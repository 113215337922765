dmx.Component('chart', {

  attributes: {
    width: {
      type: Number,
      default: 800,
    },

    height: {
      type: Number,
      default: 600,
    },

    responsive: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: 'line',
      enum: ['line', 'area', 'bar', 'horizontalBar', 'pie', 'doughnut', 'radar', 'polarArea'],
    },

    colors: {
      type: [String, Array],
      default: 'default',
    },

    legend: {
      type: String,
      default: '',
    },

    data: {
      type: Array,
      default: [],
    },

    labels: {
      type: String,
      default: '',
    },

    nogrid: {
      type: Boolean,
      default: false,
    },

    points: {
      // line/area/radar
      type: Boolean,
      default: false,
    },

    pointStyle: {
      // line/area/radar
      type: String,
      default: 'circle',
      enum: ['circle', 'cross', 'crossRot', 'dash', 'line', 'rect', 'rectRounded', 'rectRot', 'star', 'triangle'],
    },

    pointSize: {
      // line/area/radar
      type: String,
      default: 3,
    },

    smooth: {
      // line/area/radar (smooth lines)
      type: Boolean,
      default: false,
    },

    thickness: {
      // all
      type: Number,
      default: 1,
    },

    dashed: {
      // line/area/radar
      type: Boolean,
      default: false,
    },

    stacked: {
      // line/area/bar/horizontalBar
      type: Boolean,
      default: false,
    },

    multicolor: {
      // bar/horizontalBar
      type: Boolean,
      default: false,
    },

    cutout: {
      // doughnut
      type: Number,
      default: 50, // percent
    },

    noanimation: {
      // all
      type: Boolean,
      default: false, // disable animations
    },

    fullbar: {
      // bar/horizontalBar
      type: Boolean,
      default: false, // make bars full size
    },

    labelX: {
      type: String,
      default: '',
    },

    labelY: {
      type: String,
      default: '',
    },
  },

  colors: {
    default: ['#1c9f8d', '#d94712', '#2d81b9', '#1aa042', '#ad1999', '#d89515', '#d83148', '#7e2dad', '#828280', '#ad312f', '#1c9f8d', '#d94712', '#2d81b9', '#1aa042', '#ad1999', '#d89515', '#d83148', '#7e2dad', '#828280', '#ad312f'],
    colors1: ['#5DA5DA', '#FAA43A', '#60BD68', '#F17CB0', '#B2912F', '#B276B2', '#DECF3F', '#F15854', '#4D4D4D', '#5DA5DA', '#FAA43A', '#60BD68', '#F17CB0', '#B2912F', '#B276B2', '#DECF3F', '#F15854', '#4D4D4D'],
    colors2: ['#5cbae6', '#b6d957', '#fac364', '#d998cb', '#f2d249', '#93b9c6', '#ccc5a8', '#52bacc', '#98aafb', '#5cbae6', '#b6d957', '#fac364', '#d998cb', '#f2d249', '#93b9c6', '#ccc5a8', '#52bacc', '#98aafb'],
    colors3: ['#3678b3', '#f47d0d', '#479f2f', '#ca2227', '#9068bc', '#87564b', '#da77c1', '#7f7f7f', '#bbbc26', '#4bbfcf', '#3678b3', '#f47d0d', '#479f2f', '#ca2227', '#9068bc', '#87564b', '#da77c1', '#7f7f7f', '#bbbc26', '#4bbfcf'],
    colors4: ['#b1c7e8', '#f8b978', '#a2df8b', '#f69795', '#c3b0d5', '#bf9b94', '#f1b5d2', '#c7c7c7', '#dbda8e', '#a6dae5', '#b1c7e8', '#f8b978', '#a2df8b', '#f69795', '#c3b0d5', '#bf9b94', '#f1b5d2', '#c7c7c7', '#dbda8e', '#a6dae5'],
    colors5: ['#3a3d79', '#66793a', '#886c32', '#7e3c39', '#764273', '#4383bd', '#db520a', '#4ba355', '#746cb0', '#636363', '#3a3d79', '#66793a', '#886c32', '#7e3c39', '#764273', '#4383bd', '#db520a', '#4ba355', '#746cb0', '#636363'],
    colors6: ['#5356a2', '#8ea153', '#b99d3b', '#a5484a', '#9e5293', '#76afd6', '#f38b3c', '#80c377', '#9e9ac8', '#969696', '#5356a2', '#8ea153', '#b99d3b', '#a5484a', '#9e5293', '#76afd6', '#f38b3c', '#80c377', '#9e9ac8', '#969696'],
    colors7: ['#6c70ce', '#b8ce6c', '#e2b853', '#cc606b', '#c66ebc', '#a4cae1', '#f5ac6b', '#a8d99b', '#bcbddc', '#bdbdbd', '#6c70ce', '#b8ce6c', '#e2b853', '#cc606b', '#c66ebc', '#a4cae1', '#f5ac6b', '#a8d99b', '#bcbddc', '#bdbdbd'],
    colors8: ['#9c9fde', '#cfda9c', '#e4ca94', '#df959b', '#d89ed5', '#c9dbef', '#f8cfa2', '#cbe9c0', '#dadaeb', '#d9d9d9', '#9c9fde', '#cfda9c', '#e4ca94', '#df959b', '#d89ed5', '#c9dbef', '#f8cfa2', '#cbe9c0', '#dadaeb', '#d9d9d9'],
    colors9: ['#f44336', '#8bc34a', '#03a9f4', '#ffc107', '#e91e63', '#cddc39', '#00bcd4', '#ff9800', '#9c27b0', '#009688', '#f44336', '#8bc34a', '#03a9f4', '#ffc107', '#e91e63', '#cddc39', '#00bcd4', '#ff9800', '#9c27b0', '#009688'],
  },

  render (node) {
    this.$node = document.createElement("canvas");
    //this.$node.setAttribute("width", this.props.width);
    //this.$node.setAttribute("height", this.props.height);
    //this.$node.id = this.name;

    Array.from(node.attributes).forEach(attr => {
      if (!attr.name.startsWith("dmx-")) {
        this.$node.setAttribute(attr.name, attr.value);
      }
    });

    dmx.dom.replace(node, this.$node);

    if (typeof this.props.colors == "string") {
      this.props.colors = this.colors[this.props.colors] || this.colors.default;
    }

    this.datasets = this.getDatasets(node);

    this.performUpdate();
  },

  performUpdate (updatedProps) {
    var that = this;
    var chartType = this.props.type;
    var options = {
      type:
        chartType == "area"
          ? "line"
          : chartType == "horizontalBar"
          ? "bar"
          : chartType,
      options: {
        responsive: this.props.responsive,
        layout: { padding: 5 },
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                var dataset = tooltipItem.dataset; //data.datasets[tooltipItem.datasetIndex];

                if (dataset.tooltipExpression) {
                  return dmx.parse(
                    dataset.tooltipExpression,
                    new dmx.DataScope(
                      {
                        $label: dataset.label,
                        $value: dataset.data[tooltipItem.dataIndex],
                      },
                      that
                    )
                  );
                }

                if (chartType == "doughnut" || chartType == "pie") {
                  var label = that.chart.data.labels[tooltipItem.dataIndex];
                  var value = ": " + dataset.data[tooltipItem.dataIndex];

                  if (Array.isArray(label)) {
                    label = label.slice();
                    label[0] += value;
                  } else {
                    label += value;
                  }

                  return label;
                } else if (chartType == "palarArea") {
                  return (
                    that.chart.data.labels[tooltipItem.dataIndex] + ": " + tooltipItem.label
                  );
                } else {
                  var label = dataset.label || "";
                  if (label) {
                    label += ": ";
                  }

                  var value = tooltipItem.formattedValue;
                  if (value != null) {
                    label += value;
                  } else {
                    label += tooltipItem.label;
                  }

                  return label;
                }
              },
            },
          },
        },
      },
    };

    if (typeof this.props.colors == "string") {
      this.props.colors = this.colors[this.props.colors] || this.colors.default;
    }

    if (this.props.noanimation) {
      options.options.animation = { duration: 0 };
      options.options.hover = { animationDuration: 0 };
    }

    if (this.props.legend) {
      options.options.plugins.legend.display = true;
      options.options.plugins.legend.position = this.props.legend;
    }

    if (this.props.type == "line" || this.props.type == "area") {
      options.options.scales = {
        x: {
          grid: { display: !this.props.nogrid },
        },
        y: {
          grid: { display: !this.props.nogrid },
          stacked: this.props.stacked,
          beginAtZero: true,
        },
      };

      this.datasets.forEach(function (dataset, i) {
        var color = this.props.colors[i];

        Object.assign(dataset, {
          fill: this.props.type == "area",
          lineTension: this.props.smooth ? 0.4 : 0,
          backgroundColor: color + "80", //Color(color).alpha(0.5).rgbaString(),
          borderWidth: +this.props.thickness,
          borderColor: color,
          borderDash: this.props.dashed ? [5, 5] : [],
          pointStyle: this.props.pointStyle,
          pointBackgroundColor: color,
          pointBorderColor: color,
          pointBorderWidth: 1,
          pointHitRadius: 10,
          pointRadius: this.props.points ? +this.props.pointSize : 0,
          pointHoverRadius: +this.props.pointSize,
        });
      }, this);
    }

    if (this.props.type == "bar" || this.props.type == "horizontalBar") {
      options.options.indexAxis =
        this.props.type == "horizontalBar" ? "y" : "x";

      options.options.scales = {
        x: {
          grid: { display: !this.props.nogrid },
          stacked: this.props.stacked,
          beginAtZero: true,
        },
        y: {
          grid: { display: !this.props.nogrid },
          stacked: this.props.stacked,
          beginAtZero: true,
        },
      };

      this.datasets.forEach(function (dataset, i) {
        var color = this.props.colors[i];

        Object.assign(dataset, {
          backgroundColor: this.props.multicolor
            ? this.props.colors.map(function (color) {
                return color + "80";
              })
            : color + "80",
          borderColor: this.props.multicolor ? this.props.colors : color,
          borderDash: this.props.dashed ? [5, 5] : [],
          borderWidth: +this.props.thickness,
          barPercentage: this.props.fullbar ? 1 : 0.9,
          categoryPercentage: this.props.fullbar ? 1 : 0.8,
        });
      }, this);
    }

    if (this.props.type == "polarArea" || this.props.type == "radar") {
      options.options.scale = {
        grid: { display: !this.props.nogrid },
        beginAtZero: true,
      };

      this.datasets.forEach(function (dataset, i) {
        var color = this.props.colors[i];

        Object.assign(dataset, {
          fill: true,
          lineTension: this.props.smooth ? 0.4 : 0,
          borderWidth: +this.props.thickness,
          borderDash: this.props.dashed ? [5, 5] : [],
          borderColor:
            this.props.type == "polarArea" ? this.props.colors : color,
          backgroundColor:
            this.props.type == "polarArea"
              ? this.props.colors.map(function (color) {
                  return color + "50";
                })
              : color + "50",
          pointStyle: this.props.pointStyle,
          pointBackgroundColor:
            this.props.type == "polarArea" ? this.props.colors : color,
          pointBorderColor:
            this.props.type == "polarArea" ? this.props.colors : color,
          pointBorderWidth: 1,
          pointHitRadius: 10,
          pointRadius: this.props.points ? +this.props.pointSize : 0,
          pointHoverRadius: +this.props.pointSize,
        });
      }, this);
    }

    if (this.props.type == "pie" || this.props.type == "doughnut") {
      this.datasets.forEach(function (dataset, i) {
        var color = this.props.colors[i];

        Object.assign(dataset, {
          backgroundColor: this.props.colors.map(function (color) {
            return color + "80";
          }),
          borderColor: this.props.colors,
          borderWidth: +this.props.thickness,
        });
      }, this);
    }

    if (this.props.type == "doughnut") {
      options.options.cutoutPercentage = this.props.cutout;
    }

    if (this.props.labelX && options.options && options.options.scales) {
      options.options.scales.x.ticks = {
        callback: function (value, index, values) {
          var label = dmx.parse(
            that.props.labelX,
            new dmx.DataScope({ $value: value, $index: index }, that)
          );
          if (label != null) {
            return label;
          }
          return this.getLabelForValue(value);
        },
      };
    }

    if (this.props.labelY && options.options && options.options.scales) {
      options.options.scales.y.ticks = {
        callback: function (value, index, values) {
          var label = dmx.parse(
            that.props.labelY,
            new dmx.DataScope({ $value: value, $index: index }, that)
          );
          if (label != null) {
            return label;
          }
          return this.getLabelForValue(value);
        },
      };
    }

    if (JSON.stringify(options) != JSON.stringify(this.options)) {
      if (this.props.type) {
        this.options = dmx.clone(options);

        options.data = this.getData();

        if (this.chart) this.chart.destroy();
        this.chart = new Chart(this.$node, dmx.clone(options));
      }
    } else if (this.chart) {
      var data = this.getData();
      var needUpdate = false;

      if (
        JSON.stringify(this.chart.data.labels) != JSON.stringify(data.labels)
      ) {
        this.chart.data.labels = data.labels;
        needUpdate = true;
      }

      if (data.datasets.length != this.chart.data.datasets.length) {
        this.chart.data.datasets = data.datasets;
        needUpdate = true;
      } else {
        data.datasets.forEach(function (dataset, i) {
          [
            "label",
            "backgroundColor",
            "borderColor",
            "borderWidth",
            "dataExpression",
            "tooltipExpression",
            "data",
          ].forEach(function (prop) {
            if (
              JSON.stringify(this.chart.data.datasets[i][prop]) !=
              JSON.stringify(dataset[prop])
            ) {
              this.chart.data.datasets[i][prop] = dataset[prop];
              needUpdate = true;
            }
          }, this);
        }, this);
      }

      if (needUpdate) {
        this.chart.update();
      }
    }
  },

  destroy () {
    if (this.chart) this.chart.destroy();
  },

  getDatasets (node) {
    var datasets = [];

    for (var i = 1; i <= 20; i++) {
      if (node.hasAttribute("dataset-" + i + ":value")) {
        datasets.push({
          label: node.hasAttribute("dataset-" + i + ":label")
            ? node.getAttribute("dataset-" + i + ":label")
            : "dataset " + i,
          backgroundColor: this.props.colors.map(function (color) {
            return color + "80";
          }),
          borderColor: this.props.colors,
          borderWidth: 1,
          dataExpression: node.getAttribute("dataset-" + i + ":value"),
          tooltipExpression: node.getAttribute("dataset-" + i + ":tooltip"),
          data: [],
        });
      }
    }

    return datasets;
  },

  getData () {
    this.datasets.map(function (dataset) {
      var items = dmx.repeatItems(this.props.data);

      dataset.data = items.map(function (data) {
        return +dmx.parse(
          dataset.dataExpression,
          new dmx.DataScope(data, this)
        );
      }, this);

      return [];
    }, this);

    return {
      labels: this.getLabels(),
      datasets: this.datasets,
    };
  },

  getLabels () {
    var items = dmx.repeatItems(this.props.data);

    return items.map(function (data, index) {
      if (this.props.labels) {
        return dmx.parse(this.props.labels, new dmx.DataScope(data, this));
      } else {
        return "value " + (index + 1);
      }
    }, this);
  },

});
